import DsfHomePage from '../views/HomePage';
import DsfAllPage from '../views/AllPage';
import DsfMyPost from '../views/MyPost';
import DsfMyReply from '../views/MyReply';
import DsfPageDetail from '../views/PageDetail';
import DsfErrorTips from '../views/ErrorTips';

let router = [
  {
    path: "/pc/bbs/home",
    name: "home",
    meta: {
      title: "论坛首页",
      needLogin: true
    },
    component: DsfHomePage
  },
  {
    path: "/pc/bbs/allPage",
    name: "allPage",
    meta: {
      title: "所有帖子",
      needLogin: true
    },
    component: DsfAllPage
  },
  {
    path: "/pc/bbs/myPost",
    name: "myPost",
    meta: {
      title: "我的帖子",
      needLogin: true
    },
    component: DsfMyPost
  },
  {
    path: "/pc/bbs/myReply",
    name: "myReply",
    meta: {
      title: "我的回复",
      needLogin: true
    },
    component: DsfMyReply
  },
  {
    path: "/pc/bbs/pageDetail",
    name: "pageDetail",
    meta: {
      title: "帖子详情",
      needLogin: true
    },
    component: DsfPageDetail
  },
  {
    path: "/pc/bbs/distillatedPosts",
    meta: {
      title: "精华帖子",
      needLogin: true
    },
    component: DsfHomePage
  },
  {
    path: "/pc/bbs/postReview",
    meta: {
      title: "帖子审查",
      needLogin: true
    },
    component: DsfHomePage
  },
  {
    path: "/pc/bbs/createPost",
    meta: {
      title: "发布帖子",
      needLogin: true
    },
    component: DsfHomePage
  },
  {
    path: "/pc/bbs/plateManagement",
    meta: {
      title: "板块管理",
      needLogin: true
    },
    component: DsfHomePage
  },
  {
    path: "/pc/bbs/error",
    meta: {
      title: "错误页面",
      needLogin: false
    },
    component: DsfErrorTips
  },
];

export default router;