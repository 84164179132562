<template>
  <div class="list-content">
    <div class="not-data" v-if="!listData || !listData.length">暂无数据</div>
    <template v-else>
      <div class="item" v-for="item in listData" :key="item.topicId" @click="handleDetail(item, $event)">
        <!-- <div
          class="topic-review-status"
          v-if="status && status != '全部' && managerStatus"
        >
          <span> {{ status }}</span>
        </div> -->
        <div class="topic-review-status" v-if="managerStatus">
          <span v-if="item.statusValue === '0'" style="color: red"> 已关闭 </span>
          <span v-else-if="item.reviewStatusValue === '1'"> 已发布 </span>
          <span v-else-if="item.reviewStatusValue === '0'" style="color: red"> 未通过审核 </span>
          <span v-else-if="item.reviewStatusValue === '2'"> 待审核 </span>
        </div>
        <div class="topic-mannager">
          <div class="topic-name ellipsis">
            <span v-if="item.isTop">置顶</span><span v-if="item.good">精</span>
            {{ "【" + item.catalogName + "】" + item.topicName }}
          </div>
        </div>
        <!-- <p class="topic-desc ellipsis">{{ item.topicDesc }}</p> -->
        <p class="topic-desc ellipsis" v-html="item.topicDesc"></p>
        <div class="topic-img" v-if="item.topicImg && item.topicImg.length">
          <el-image
            v-for="(img, index) in item.topicImg"
            :key="index"
            :src="img"
            :preview-src-list="item.topicImg"
            fit="cover"
            title="图片"
          ></el-image>
        </div>
        <div class="topic-user-name">
          <div class="left">
            <div class="name n-t">
              <i class="el-icon-user"></i>
              <!-- {{ item.anonymousValue == 1 ? "匿名" : item.userName }} -->
              {{ item.userName || "匿名" }}
            </div>
            <div class="time n-t">{{ item.publishDate }}</div>
            <div class="time n-t" v-if="item.belongText">{{ item.belongText }}</div>
          </div>
          <div class="reply-icons" v-if="!managerStatus">
            <i
              :class="[item.collected == 1 ? 'el-icon-star-on' : 'el-icon-star-off', { active: item.collected == 1 }]"
              @click="star(item)"
              title="收藏"
            >
              {{ item.collectNum || 0 }}
            </i>
            <i class="el-icon-view" title="点击量"> {{ item.viewNum || 0 }}</i>
            <i class="el-icon-chat-line-square" title="回复"> {{ item.replyNum || 0 }}</i>
          </div>
          <div class="reply-icons" v-if="managerStatus">
            <DsfButton size="small" v-if="item.reviewStatusValue === '1' && item.statusValue === '1'" @click.stop="closePost(item)">
              关闭帖子
            </DsfButton>
            <DsfButton type="plain" size="small" v-if="item.statusValue === '0'" @click.stop="startPost(item)">开启帖子</DsfButton>
            <DsfButton size="small" class="edit" v-if="item.reviewStatusValue === '2'" @click.stop="passPost(item)">审核通过</DsfButton>
            <DsfButton size="small" class="edit" v-if="item.reviewStatusValue === '2'" @click.stop="rejectPost(item)">不通过</DsfButton>
            <!-- <DsfButton
              class="edit"
              v-if="item.reviewStatusValue == 0"
              @click.stop="agin(item)"
            >重新审核</DsfButton
            >
            <DsfButton
              class="del"
              @click="delPost(item)"
              v-if="1 == 1"
            >删除帖子</DsfButton
            > -->
          </div>
        </div>
      </div>
      <el-pagination
        v-if="showPage"
        background
        layout="prev, pager, next"
        :current-page.sync="pageNumber"
        :page-size="pageSize"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </template>
    <el-dialog title="帖子详情" :visible.sync="dialogVisible" width="60%" :append-to-body="true">
      <div
        v-if="detailContent.content"
        class="bbs-detail"
        style="
          height: 60vh;
          overflow-y: auto;
          padding: 20px 50px;
          box-sizing: border-box;
          margin-bottom: 30px;
          text-align: center;
          background-color: #fff;
        "
      >
        <h3>{{ detailContent.content.topicName }}</h3>
        <p class="detail-info" style="margin: 30px 0; color: #a5aeb6">
          <span>楼主: {{ detailContent.content.userName }} </span><span>时间: {{ detailContent.content.dsCreateTime }}</span>
          <span v-if="detailContent.content.belongName">{{ detailContent.content.belongName }}</span>
        </p>
        <div
          class="detail-content"
          style="border-top: 1px solid #dbdbdb; padding: 30px 20px 0 20px; text-align: left"
          v-html="detailContent.content.topicContent"
        ></div>
        <div
          class="detail-imgs"
          style="display: block; margin: 20px auto; max-width: 969px; width: auto"
          v-if="detailContent.content.topicImg && detailContent.content.topicImg.length"
        >
          <el-image v-for="(img, index) in detailContent.content.topicImg" :key="index" :src="img" fit="cover"></el-image>
        </div>
      </div>
      <span slot="footer">
        <div style="text-align: center" v-if="detailContent.content">
          <DsfButton size="small" type="plain" @click="dialogVisible = false">取 消</DsfButton>
          <DsfButton
            size="small"
            v-if="dialogPost.reviewStatusValue === '1' && dialogPost.statusValue === '1'"
            @click.stop="closePost(detailContent.content)"
          >
            关闭帖子
          </DsfButton>
          <DsfButton type="plain" size="small" v-if="dialogPost.statusValue === '0'" @click.stop="startPost(detailContent.content)">
            开启帖子
          </DsfButton>
          <DsfButton size="small" class="edit" v-if="dialogPost.reviewStatusValue === '2'" @click.stop="passPost(detailContent.content)">
            审核通过
          </DsfButton>
          <DsfButton size="small" class="edit" v-if="dialogPost.reviewStatusValue === '2'" @click.stop="rejectPost(detailContent.content)">
            不通过
          </DsfButton>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DsfListContent",
  props: {
    listContent: {
      type: Object,
      default() {
        return {};
      },
    },
    showPage: {
      type: Boolean,
      default: true,
    },
    managerStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      srcList: "",
      url: "http://117.184.59.230:18821/zentao/file-read-204355.png",
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      listData: [],
      dialogPost: {},
      showTopicName: "",
      dialogVisible: false,
      detailContent: {},
    };
  },
  created() {},
  methods: {
    //审核帖子
    verifypost(topicId, type, info) {
      var _this = this;
      var loading = dsf.layer.loading();
      this.$http
        .post("web/topic/verify", { topicId, type }, $$webRoot.bbs)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message(info, true);
            // location.reload();
            _this.dialogVisible = false;
            _this.$emit("sorted", type, "待审核");
          } else {
            dsf.layer.message(res.message || "请求异常", false);
          }
        })
        .error(function (err) {
          //错误
          dsf.layer.message((err && err.message) || "请求异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },

    //停用 or 启用帖子
    changeTopicStatus(topicId, status, info) {
      var _this = this;
      var loading = dsf.layer.loading();
      this.$http
        .post("web/topic/changeTopicStatus", { topicId, status }, $$webRoot.bbs)
        .done(function (res) {
          if (res.success) {
            dsf.layer.message(info, true);
            _this.dialogVisible = false;
            location.reload();
          } else {
            dsf.layer.message(res.message || "请求异常", false);
          }
        })
        .error(function (err) {
          //错误
          dsf.layer.message((err && err.message) || "请求异常", false);
        })
        .always(function () {
          dsf.layer.closeLoading(loading);
        });
    },
    closePost(item) {
      dsf.layer
        .confirm("确认是否要关闭帖子", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.changeTopicStatus(item.topicId, 0, "关闭帖子成功");
        })
        .catch(() => {
          //取消
        });
    },
    startPost(item) {
      dsf.layer
        .confirm("该贴子已被关闭，确认是否要开启", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.changeTopicStatus(item.topicId, 1, "成功恢复帖子");
        })
        .catch(() => {
          //取消
        });
    },
    passPost(item) {
      this.verifypost(item.topicId, 1, "审核通过");
    },
    rejectPost(item) {
      this.verifypost(item.topicId, 0, "操作成功");
    },
    delPost(item) {
      // console.log("删除", item);
    },
    loadData() {
      _(this.listContent?.list).forEach((element, index) => {
        // console.log(index, element.topicImg);
        element.topicImg = element.topicImg ? this.getImgList(element.topicImg) : [];
      });
      this.listData = this.listContent?.list;
      if (this.listContent.pageNumber) {
        this.pageSize = this.listContent.pageSize;
        this.pageNumber = this.listContent.pageNumber;
        this.total = this.listContent.totalRow;
      }
    },
    handleDetail(item, e) {
      var cname = "";
      var catalogId = "";
      var userId = "";
      var path = "/pc/bbs/pageDetail?id=" + item.topicId;
      if (this.$route.query.c) {
        path += "&c=" + this.$route.query.c + "&cname=" + item.catalogName;
      }
      if (this.$route.name === "myPost") {
        path += "&cname=我的帖子";
      }
      if (this.$route.query.u) {
        path += "&u=" + this.$route.query.u + "&cname=他的帖子";
      }
      if (this.$route.name === "myReply") {
        path += "&cname=我的回复";
      }
      if (this.$route.query.star === "1") {
        path += "&cname=我的收藏";
      }
      if (item.topicId && e.target.title != "收藏" && e.target.title != "图片" && !this.managerStatus) {
        this.$router.push({
          // path: "/pc/bbs/pageDetail?id=" + item.topicId + "&cname=" + cname + "&c=" + catalogId + "&u=" + userId,
          path,
          params: { title: cname },
        });
      } else if (e.target.title != "图片" && this.managerStatus) {
        this.dialogVisible = true;
        this.dialogPost = item;
        this.getDetail(item.topicId);
      }
    },
    getDetail(topicId) {
      this.$http
        .get(
          "web/topic/verifyDetail",
          {
            topicId,
            // vType: 0,
            // fPageNo: 1,
            // fPageSize: 10,
          },
          $$webRoot.bbs
        )
        .done(({ success, data, message }) => {
          if (success) {
            // console.log(data);
            var content = data.content.topicContent;
            data.content.topicContent = content?.replace(/\n/g, "<br/>").replace(/\s/g, "&nbsp;");
            data.content.topicImg = data.content.topicImg ? this.getImgList(data.content.topicImg) : "";
            this.detailContent = data;
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {
          this.detailLoading = false;
        });
    },

    star(item) {
      if (item.collected == 1) {
        dsf.layer
          .confirm({
            title: "信息",
            message: "确认取消收藏吗？",
          })
          .then(() => {
            this.saveOrCancel(item);
          })
          .catch(() => {});
      } else {
        this.saveOrCancel(item);
      }
    },
    saveOrCancel(item) {
      let params = {
        ywlxValue: "bbs_topic",
        ywlxText: "BBS主题",
        ywid: item.topicId,
      };
      this.$http
        .post("dsfa/collect/saveOrCancel", params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            item.collected = res.data.isDo ? 1 : 0;
            item.collectNum = res.data.isDo ? Number(item.collectNum) + 1 : Number(item.collectNum) - 1;
            dsf.layer.message(res.message);
            // console.log(this.$route.query.star);
            if (this.$route.query.star == 1) {
              //刷新列表
              if (this.listContent.list.length < 2) {
                // console.log(this.listContent);
                if (this.listContent.pageNumber == 2) {
                  this.$emit("page-event", 1);
                } else {
                  this.$emit("page-event", --this.listContent.pageNumber);
                }
              } else {
                this.$emit("page-event");
              }
            }
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    handleCurrentChange(val) {
      this.$emit("page-event", val);
    },
    getImgList(imgs) {
      if (!imgs || imgs === "[]" || _.isEqual(imgs, [])) {
        return [];
      }
      let _imgs = !_.isArray(imgs) ? JSON.parse(imgs) : imgs;
      if (_imgs) {
        return _imgs.map((v) => (v.relativePath ? dsf.url.getWebPath(v.relativePath) : v));
      } else {
        return [];
      }
    },
  },
  watch: {
    listContent: {
      handler(v, o) {
        if (v) {
          this.loadData();
        }
      },
      immediate: true,
      // deep: true,
    },
    dialogVisible(val) {
      if (!val) {
        this.detailContent = {};
      }
    },
  },
};
</script>
