//注入按钮
import "./sysButton";
import * as componentsCtrl from "./components";

const components = [
  ...Object.values(componentsCtrl)
]

function install(Vue) {
  components.forEach((it) => {
    Vue.component(it.name, it);
    if (!window.$components['bbs']) {
      window.$components['bbs'] = {}
    }
    window.$components['bbs'][it.name] = it;
  })
}
export default install;

export { install };
