<template>
  <dsf-bbs-views :showSorted="false" :page-title="pageTitle" @sorted="sortedQuery">
    <el-tabs v-model="activeName">
      <el-tab-pane :key="item.name" v-for="item in tabPane" :label="item.label" :name="item.name">
        <!-- 
                  <dsf-list-content
            v-if="item.name == activeName && listContent[activeName]"
            :list-content="listContent[activeName]"
            @page-event="queryData"
          /> -->

        <!-- <dsf-list-content :list-content="listContent" @page-event="queryData" /> -->
        <!-- && !listContent.data.length -->
        <div class="not-data" v-if="!listContent">暂无数据</div>
        <template v-else>
          <div v-for="(item, index) in listContent.data" :key="index" class="reply-list">
            <div class="reply-item">
              <div class="user-head">
                <div class="user-photo">
                  <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.dsCreateUserId" @error="errorHandler">
                    <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                  </el-avatar>
                </div>
                <div class="reply-box">
                  <div class="name">
                    {{ item.dsCreateUserName }}
                    <span class="comment-plnr" v-if="item.plnr" v-html="'：' + item.plnr"></span>
                    <div v-if="item.img && item.img.length" class="img-list">
                      <el-image v-for="(img, index) in item.img" :key="index" :src="img" fit="cover">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </div>
                  </div>
                  <div class="reply-date">
                    <div>{{ item.dsCreateTime }}</div>
                    <div class="reply-button" @click="handleDelete(item)">删除</div>
                  </div>
                </div>
              </div>
              <template v-if="item.replyData">
                <div class="topic-comment">
                  <template v-if="item.replyType === 'comment'">
                    <div class="user-head">
                      <div :class="['user-photo', { 'user-photo-cursor': item.replyData.dsCreateUserId != item.dsCreateUserId }]" @click="userInfo(item)">
                        <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.replyData.dsCreateUserId" @error="errorHandler">
                          <i class="el-icon-picture-outline-round" style="font-size: 20px; line-height: 40px"></i>
                        </el-avatar>
                      </div>
                      <div class="reply-box">
                        <p class="name">{{ item.replyData.dsCreateUserName }}</p>
                        <div class="reply-date">
                          <div>{{ item.replyData.dsCreateTime }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="reply-content">
                      <div class="info ellipsis" @click="handleDetail(item, $event)" v-html="item.replyData.plnr"></div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="reply-content" @click="handleDetail(item, $event)">
                      <div class="title ellipsis">{{ item.replyData.topicName }}</div>
                      <div class="info ellipsis">{{ item.replyData.topicDesc }}</div>
                    </div>
                    <div class="topic-user-name">
                      <div class="left">
                        <div class="name n-t ellipsis">
                          <!-- <i class="el-icon-user"></i>{{ item.replyData.anonymousValue == 1 ? "匿名" : item.replyData.userName }} -->
                          <i class="el-icon-user"></i>{{ item.replyData.userName || "匿名" }}
                        </div>
                        <div class="time n-t">{{ item.replyData.publishDate }}</div>
                      </div>
                      <div class="reply-icons" @click="handleDetail(item, $event)">
                        <i :class="['el-icon-star-on', { active: item.replyData.collected == 1 }]" title="收藏" @click="star(item.replyData)">
                          {{ item.replyData.collectNum || 0 }}
                        </i>
                        <i class="el-icon-view" title="点击量"> {{ item.replyData.viewNum || 0 }}</i>
                        <i class="el-icon-chat-line-square" title="回复"> {{ item.replyData.replyNum || 0 }}</i>
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="queryParams.pageNumber"
            :page-size="queryParams.pageSize"
            :total="listContent.total"
            @current-change="handleCurrentChange"
          />
        </template>
      </el-tab-pane>
    </el-tabs>
  </dsf-bbs-views>
</template>

<script>
import DsfBbsViews from "../components/BbsViews";
export default {
  name: "DsfMyReply",
  data() {
    return {
      queryParams: {
        userId: "",
        sorted: 0,
        pageNumber: 1,
        pageSize: 10,
      },
      tabPane: [
        {
          name: "allPage",
          label: "",
        },
      ],
      queryUrl: "web/topic/reply/list",
      activeName: "allPage",
      listContent: null,
    };
  },
  created() {
    this.queryParams.userId = this.$route.query.u;
    this.pageTitle = this.$route.query.u ? "他的回复" : "我的回复";
    this.tabPane[0].label = this.pageTitle;
    this.queryData();
  },
  methods: {
    userInfo(item) {
      if (item.dsfaCommentId && item.replyData.dsCreateUserId != item.dsCreateUserId) {
        this.$openWindow("#/pc/bbs/home?u=" + item.replyData.dsCreateUserId);
      }
    },
    queryData(pageSize) {
      // 最新 最热
      if (dsf.isDef(pageSize)) {
        this.queryParams.pageNumber = pageSize;
      }
      this.$http
        .get(this.queryUrl, this.queryParams, $$webRoot.bbs)
        .done(({ success, data, message }) => {
          if (success) {
            if (data?.data?.length) {
              _(data.data).forEach((element) => {
                element.img = this.getImgList(element.img);
              });
              this.listContent = data;
            } else {
              this.listContent = null;
            }
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    // 删除
    handleDelete(item) {
      let formData = new FormData();
      formData.append("commentId", item.dsfaCommentId);
      this.$http
        .post(
          "dsfa/platform/comment/delComment",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          $$webRoot.bbs
        )
        .done((res) => {
          if (res.success && res.state == 20000) {
            dsf.layer.message(res.message);
            if (this.listContent.data.length < 2) {
              this.queryParams.pageNumber -= 1;
              if (this.queryParams.pageNumber < 1) {
                this.queryParams.pageNumber = 1;
              }
            }
            this.queryData(this.queryParams.pageNumber);
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    sortedQuery(sorted) {
      this.queryParams.sorted = sorted || 0;
      this.queryParams.pageNumber = 1;
      this.queryData();
    },
    handleCurrentChange(val) {
      this.queryData(val);
      // this.$emit("page-event", val);
    },
    getImgList(imgs) {
      if (!imgs) return [];
      imgs = JSON.parse(imgs);
      if (!imgs) return [];
      if (typeof imgs === "string") {
        imgs = JSON.parse(imgs);
      }
      //2021-10-08 翁伟勇 增加非空判断，过滤掉数组中null成员
      let _imgs = _.filter(imgs, (it) => {
        return dsf.isDef(it);
      });
      if (_imgs.length > 0) {
        return _imgs.map((v) => dsf.url.getWebPath(v.relativePath));
      } else {
        return [];
      }
    },
    errorHandler() {
      return true;
    },
    handleDetail(item, e) {
      let topicId = item.ywid;
      var query = { ...this.$route.query };
      if (topicId && e.target.title != "收藏") {
        query.id = topicId;
        if (this.$route.query.u) {
          query.cname = "他的回复";
        } else {
          query.cname = "我的回复";
        }
        this.$router.push({
          name: "pageDetail",
          params: {},
          query,
        });
      }
    },
    star(item) {
      if (item.collected == 1) {
        dsf.layer
          .confirm({
            title: "信息",
            message: "确认取消收藏吗？",
          })
          .then(() => {
            this.saveOrCancel(item);
          })
          .catch(() => {});
      } else {
        this.saveOrCancel(item);
      }
    },
    saveOrCancel(item) {
      let params = {
        ywlxValue: "bbs_topic",
        ywlxText: "BBS主题",
        ywid: item.topic_id || item.topicId,
      };
      this.$http
        .post("dsfa/collect/saveOrCancel", params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            this.listContent.data = _.forEach(this.listContent.data, (list) => {
              if (item.topicId === list.replyData.topicId) {
                list.replyData.collected = res.data.isDo ? 1 : 0;
                list.replyData.collectNum = res.data.isDo ? Number(list.replyData.collectNum) + 1 : Number(list.replyData.collectNum) - 1;
              }
            });
            dsf.layer.message(res.message);
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
  },
  components: {
    DsfBbsViews,
  },
};
</script>
