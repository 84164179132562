<template>
  <div class="pc-error-container">
    <div class="pc-error-tips">
      <div class="pc-error-pic">
        <img
          class="pc-error-img"
          src="../../assets/styles/themes/img/error2.png"
          alt=""
        />
      </div>
      <div class="pc-error-text">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dsfErrorTips",
  data() {
    return {
      message: "页面错误",
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.$route.query.msg) {
        this.message = this.$route.query.msg;
      }
    });
  },
};
</script>

<style></style>
