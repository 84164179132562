<template>
  <div class="pc-bbs-reply-content" v-loading="replyLoading">
    <div class="replies-content" ref="content">
      <template v-if="detailContent && detailContent.replies.comment.length">
        <div class="replies-list" v-for="(item, index) in detailContent.replies.comment" :key="item.dsfaCommentId">
          <div class="left">
            <div v-if="detailContent.content.userId == item.dsCreateUserId" class="self">
              <span class="louzhu">楼主</span>
            </div>
            <div class="user-info" @click="userInfo(item)">
              <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.dsCreateUserId" @error="errorHandler">
                <i class="el-icon-picture-outline-round" style="font-size: 90px; line-height: 90px"></i>
              </el-avatar>
              <p class="user-name">{{ item.dsCreateUserName }}</p>
              <el-tooltip :content="item.belongName" placement="bottom" effect="light">
                <p class="user-name belong" :style="{ visibility: item.belongName ? 'visible' : 'hidden' }">
                  {{ item.belongName }}
                </p>
              </el-tooltip>
            </div>
          </div>
          <div class="right">
            <div class="plnr" v-html="item.plnr"></div>
            <div class="floor-page">
              <span>{{ item.floor }}</span>
              <span>{{ item.showTime }}</span>
              <span class="floor-reply" @click="replyShow(item)">回复</span>

              <div class="children-input" v-if="replyId == item.dsfaCommentId">
                <div class="dsf-bbs-textarea-box">
                  <div
                    :ref="`child-plnr-${item.dsfaCommentId}`"
                    class="dsf-bbs-detail-textarea"
                    placeholder="请输入你的回复"
                    contenteditable
                    @blur="addBlur(item.dsfaCommentId)"
                  ></div>

                  <div class="dsf-bbs-textarea-tips">
                    当前已输入{{ currentLength }}个字符, 您还可以输入{{ maxLength - currentLength >= 0 ? maxLength - currentLength : 0 }}个字符。
                  </div>
                </div>
                <div class="children-emoji-btn">
                  <div class="flex-emoji">
                    <el-popover :append-to-body="false" placement="top-start" width="942" trigger="click">
                      <bbs-emoji @change="emojiChange($event, item)" />
                      <i slot="reference" class="iconfont icon-xiaolianmanyifuwu"></i>
                    </el-popover>
                  </div>
                  <el-button size="small" class="submit-btn" @click="comment(item)">回复</el-button>
                </div>
              </div>
            </div>
            <div class="child-list" v-if="item.childList && item.childList.length">
              <p class="expand-btn">
                <span @click="item.check = !item.check" :class="{ active: item.check }"> {{ item.check ? "收起" : "展开" }}回复</span>
              </p>
              <div class="expand-content" v-show="item.check">
                <template v-for="(child, len) in item.childList">
                  <div class="expand-item" :key="child.dsfaCommentId" :data-len="len">
                    <div class="user-info" @click="userInfo(child)">
                      <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + child.dsCreateUserId" @error="errorHandler">
                        <i class="el-icon-picture-outline-round" style="font-size: 40px; line-height: 40px"></i>
                      </el-avatar>
                    </div>
                    <div class="expand-plrn">
                      <p v-if="child.dsCreateUserId !== child.bplrValue" class="plnr">
                        <span class="user">{{ child.dsCreateUserName }}</span>
                        &nbsp;回复&nbsp;
                        <span class="user">{{ child.bplrText }}：</span>
                        <span v-html="child.plnr"></span>
                      </p>
                      <p v-else class="plnr">
                        <span class="user">{{ child.bplrText }}：</span><span v-html="child.plnr"></span>
                      </p>
                      <div class="time">
                        <div class="t time-belong">
                          <span>{{ child.dsCreateTime }}</span>
                          <el-tooltip v-if="child.belongName" :content="child.belongName" placement="bottom" effect="light">
                            <span class="belong" v-if="child.belongName"> {{ child.belongName }}</span>
                          </el-tooltip>
                        </div>
                        <span class="btn" @click="replyShow(child)">回复</span>
                      </div>
                      <div class="children-input" v-if="replyId == child.dsfaCommentId">
                        <div class="dsf-bbs-textarea-box">
                          <div
                            :ref="`child-plnr-${child.dsfaCommentId}`"
                            class="dsf-bbs-detail-textarea"
                            placeholder="请输入你的回复"
                            contenteditable
                            @blur="addBlur(child.dsfaCommentId)"
                          ></div>
                          <div class="dsf-bbs-textarea-tips">
                            当前已输入{{ currentLength }}个字符, 您还可以输入{{
                              maxLength - currentLength >= 0 ? maxLength - currentLength : 0
                            }}个字符。
                          </div>
                        </div>
                        <div class="children-emoji-btn">
                          <div class="flex-emoji">
                            <el-popover :append-to-body="false" placement="top-start" width="942" trigger="click">
                              <bbs-emoji @change="emojiChange($event, child)" />
                              <i slot="reference" class="iconfont icon-xiaolianmanyifuwu"></i>
                            </el-popover>
                          </div>
                          <el-button size="small" class="submit-btn" @click="comment(child)">回复</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <p class="more" v-if="item.check && item.childList.length > 3" @click="queryChildComment(item)">
                {{ item.ismore ? "没有更多了..." : "更多评论" }}
              </p>
            </div>
          </div>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryParams.fPageNo"
          :page-size="queryParams.fPageSize"
          :total="detailContent.replies.total"
          @current-change="queryData"
        />
      </template>
      <div v-else class="not-data">暂无数据</div>
    </div>

    <el-tabs v-model="activeName" class="reply-wrapper" v-if="detailContent">
      <el-tab-pane label="发表回复" name="allPage">
        <div class="dsf-bbs-textarea-box">
          <div ref="textarea" class="dsf-bbs-detail-textarea" placeholder="请输入你的回复" contenteditable @blur="addBlur()"></div>
          <div class="dsf-bbs-textarea-tips">
            当前已输入{{ bottomLength }}个字符, 您还可以输入{{ maxLength - bottomLength >= 0 ? maxLength - bottomLength : 0 }}个字符。
          </div>
        </div>
        <div class="reply-button-wrapper">
          <el-popover :append-to-body="false" placement="right" width="942" trigger="click">
            <bbs-emoji @change="emojiChange" />
            <i slot="reference" class="iconfont icon-xiaolianmanyifuwu"></i>
          </el-popover>
          <el-button class="comment-button" @click="comment">{{ btnText }}</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BbsEmoji from "../components/BbsEmoji";

export default dsf.component({
  name: "DsfPcBbsReplyContent",
  ctrlCaption: "PC评论组件",
  mixins: [$mixins.control],
  props: {
    ywlxValue: {
      type: String,
      default: "bbs_topic",
    },
    detailContent: {
      type: Object,
      default() {
        return {};
      },
    },
    // 获取详情的请求地址
    commentListUrl: {
      type: String,
      default: "/web/topic/detail",
    },
    // 评论的请求地址
    replyUrl: {
      type: String,
      default: "/dsfa/platform/comment/addOrRefreshComment",
    },
    // 回复列表的请求地址
    replyListUrl: {
      type: String,
      default: "/dsfa/platform/comment/queryChildComment",
    },
    btnText: {
      type: String,
      default: "发表",
    },
    maxLength: {
      type: Number,
      default: 500,
    },
    replyStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: "allPage",
      replyLoading: false,
      queryParams: {
        topicId: "",
        vType: 0,
        fPageNo: 1,
        fPageSize: 10,
      },
      // detailContent: null,
      replyId: "",
      currentLength: 0,
      bottomLength: 0,
    };
  },
  created() {
    this.queryParams.topicId = this.$route.query.id;
    // 查询板块下主题信息
    if (this.isDesign) {
      this.detailContent = {
        content: {
          userName: "小明",
          userId: "001",
        },
        replies: {
          total: 1,
          comment: [
            {
              dsCreateUserId: "001",
              dsCreateUserName: "小明",
              dsCreateTime: "2021-11-01 00:00:00",
              showTime: "1小时前",
              plnr: "这是小明的回复。",
              childList: [],
            },
          ],
        },
      };
    } else {
      // 父组件传数据
      // this.queryData();
    }
  },
  mounted() {},
  updated() {},
  methods: {
    queryData(fPageNo) {
      // console.log(this.queryParams);
      this.replyLoading = true;
      // 最新 最热
      if (dsf.isDef(fPageNo)) {
        this.queryParams.fPageNo = fPageNo;
      }
      this.$http
        .get(this.commentListUrl, this.queryParams, $$webRoot.bbs)
        .done(({ success, data, message }) => {
          if (success) {
            // 讨论内容
            var repliesData = data.replies.comment;
            if (repliesData.length) {
              repliesData.forEach((item, index) => {
                // 展开 收起 加载更多
                this.$set(item, "check", true);
                this.$set(item, "ismore", false);
                this.$set(item, "floor", (this.queryParams.fPageNo - 1) * this.queryParams.fPageSize + index + 1 + "楼");
                // 处理子级
                if (item.childList) {
                  _(item.childList).forEach((item) => {
                    if (item.plnr) {
                      item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
                    }
                  });
                }
                // 当前评论
                if (item.plnr) {
                  item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
                }
              });
            }
            data.replies.comment = repliesData;
            // this.detailContent = data;
            this.$emit("set-data", data);
            this.keyUp();
            this.replyId = "";
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        })
        .always(() => {
          this.replyLoading = false;
        });
    },
    replyShow(item) {
      if (!item.dsfaCommentId) return;
      // 当前已点开 就缩回
      this.replyId = this.replyId != item.dsfaCommentId ? item.dsfaCommentId : "";
      setTimeout(() => {
        if (this.replyId) {
          this.keyUp("child-plnr-" + item.dsfaCommentId);
        }
      }, 500);
    },
    getTextLength(innerHTML) {
      var mytext = innerHTML.replace(new RegExp("&nbsp;", "g"), ""); //删除空格"&nbsp;"
      var textarr = mytext.split("<");
      var contentlength = 0;
      _.forEach(textarr, function (item) {
        var index = item.indexOf(">");
        if (item[index] !== -1) {
          contentlength = contentlength + item.substr(index + 1, item.length).length;
        }
      });
      var imgNum = this.searchTimes(innerHTML, "<img"); //图片个数
      var spaceNum = this.searchTimes(innerHTML, "&nbsp;"); //空格个数
      contentlength = contentlength + imgNum + spaceNum;
      return contentlength;
    },
    searchTimes(text, word) {
      var index = text.indexOf(word);
      var num = 0;
      while (index !== -1) {
        num++;
        index = text.indexOf(word, index + 1);
      }
      return num;
    },
    addBlur(dsfaCommentId) {
      if (dsfaCommentId) {
        var type = "child-plnr-" + dsfaCommentId;
        this.currentLength = this.getTextLength(this.$refs[type][0].innerHTML);
      } else {
        this.bottomLength = this.getTextLength(this.$refs.textarea.innerHTML);
      }
    },

    keyUp(type) {
      this.currentLength = 0;
      this.bottomLength = 0;
      if (type) {
        this.$refs[type][0].addEventListener("keyup", () => {
          this.currentLength = this.getTextLength(this.$refs[type][0].innerHTML);
        });
      } else {
        // 底部的评论框
        this.$refs.textarea.addEventListener("keyup", () => {
          this.bottomLength = this.getTextLength(this.$refs.textarea.innerHTML);
        });
      }
    },
    comment(item) {
      let plnr = "";
      var plnrlength = 0;
      if (item.dsfaCommentId) {
        plnr = this.getContent("child-plnr-" + item.dsfaCommentId);
        plnrlength = this.currentLength;
      } else {
        plnr = this.getContent();
        plnrlength = this.bottomLength;
      }
      if (plnr == "") {
        plnrlength = 0;
        dsf.layer.message("请输入内容~", false);
        return;
      }
      if (plnrlength > this.maxLength) {
        dsf.layer.message(`当前字数为${plnrlength}字,评论字数限制在${this.maxLength}字以内`, false);
        return;
      }
      let params = {
        plnr: plnr,
        ywlxValue: this.ywlxValue,
        ywid: this.detailContent.content.topicId,
        treeinfoPid: item.dsfaCommentId,
        bplrText: this.detailContent.content.userName,
        bplrValue: this.detailContent.content.userId,
      };
      this.$http
        .post(this.replyUrl, params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            dsf.layer.message(res.message);
            this.reset();
            this.queryData();
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    queryChildComment(item) {
      if (item.ismore) return;
      let params = {
        childPageNo: 1,
        childPageSize: 10000,
        businessType: this.ywlxValue,
        businessId: this.detailContent.content.topicId,
        pId: item.dsfaCommentId,
      };
      this.$http
        .get(this.replyListUrl, params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            item.ismore = true;
            if (res.data.length) {
              _(res.data).forEach((item) => {
                if (item.plnr) {
                  item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
                }
              });
              item.childList = res.data;
              // item.childList.push(...res.data);
            }
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    errorHandler() {
      return true;
    },
    userInfo(data) {
      if (data.dsfaCommentId && data.dsCreateUserId) {
        this.$openWindow("#/pc/bbs/home?u=" + data.dsCreateUserId);
      }
    },
    // 只看楼主
    checkTpye() {
      this.queryParams.vType = this.queryParams.vType == 0 ? 1 : 0;
      this.queryData();
    },
    getContent(type) {
      let text = null;
      if (type) {
        text = this.$refs[type][0].innerHTML;
      } else {
        // 底部的评论框
        text = this.$refs.textarea.innerHTML;
      }
      text = text.replace(/(^\s*|&nbsp;)|(\s*$)/g, "");
      return text;
    },
    emojiChange(item, list) {
      let img = document.createElement("img");
      img.dataset.key = item.key;
      img.classList.add("emoji");
      img.src = item.value;
      if (list) {
        this.$refs["child-plnr-" + list.dsfaCommentId][0].appendChild(img);
        this.currentLength += 1;
      } else {
        this.$refs.textarea.appendChild(img);
        this.bottomLength += 1;
      }
    },
    reset() {
      this.$refs.textarea.innerHTML = "";
    },
  },
  components: {
    BbsEmoji,
  },
  watch: {
    detailContent(val) {
      if (val.content) {
        setTimeout(() => {
          this.keyUp();
        }, 500);
      }
    },
  },
});
</script>
