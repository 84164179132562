<template>
  <div class="pc-reply-component" v-loading="replyLoading">
    <div class="p-20">
      <div ref="textarea" class="dsf-bbs-detail-textarea" contenteditable @input="textInput"></div>
      <p class="maxlength">
        当前已输入{{ currentLength }}个字符, 您还可以输入{{ maxLength - currentLength >= 0 ? maxLength - currentLength : 0 }}个字符。
      </p>
      <div class="tool-box">
        <div class="left">
          <el-popover :append-to-body="false" placement="bottom-start" popper-class="tool-popper" trigger="click">
            <bbs-emoji @change="emojiChange" />
            <el-button
              slot="reference"
              v-show="bts.indexOf('emoji') > -1"
              type="text"
              size="medium"
              class="tool-btn"
              icon="iconfont icon-xiaolian"
              title="表情"
            >
              表情
            </el-button>
          </el-popover>
          <el-upload
            ref="upload"
            multiple
            :accept="uploadFileExts"
            :headers="headersParam"
            :action="requestUrl"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-exceed="handleExceed"
            :limit="parseInt(maxCount)"
          >
            <el-button v-show="bts.indexOf('img') > -1" type="text" size="medium" class="tool-btn" icon="iconfont icon-tupiantianjia" title="图片">
              图片
            </el-button>
          </el-upload>
        </div>
        <div class="right">
          <el-button :disabled="btnDisabled" class="comment-button" size="medium" small @click="commentReply">{{ btnText }}</el-button>
        </div>
      </div>
      <div class="file-imgs" v-loading="upfileLoading">
        <div v-for="(item, index) in fileList" class="item" :key="item.id">
          <el-image :src="dsf.url.getWebPath(item.relativePath)" :alt="item.originalFileName" :preview-src-list="srcList" fit="contain" />
          <i class="del iconfont icon-icon2" @click="handleRemove(index)" title="删除"></i>
        </div>
      </div>
    </div>
    <el-divider />
    <div class="replies-content p-20" ref="content">
      <h3 class="all-content">
        全部讨论 <span v-if="detailContent">{{ detailContent.total }}</span>
      </h3>
      <el-input
        placeholder="请输入关键字"
        v-model="queryParams.keywords"
        clearable
        class="search-wrap"
        maxlength="50"
        @keyup.enter.native="queryData()"
      >
        <el-button slot="append" @click="queryData()">搜索</el-button>
      </el-input>
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick" class="replies-tabs">
          <template v-for="item in tabs">
            <el-tab-pane :key="item.name" :label="item.label" :name="item.name">
              <template v-if="item.name == 'first'"> -->
      <template v-if="detailContent && detailContent.comment.length">
        <div class="replies-list" v-for="(item, index) in detailContent.comment" :key="item.dsfaCommentId">
          <div class="left">
            <div class="user-info" @click="userInfo(item)">
              <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + item.dsCreateUserId" @error="errorHandler">
                <i class="el-icon-picture-outline-round" style="font-size: 48px; line-height: 48px"></i>
              </el-avatar>
            </div>
          </div>
          <div class="right">
            <div class="user-name">
              <div class="name">
                {{ item.dsCreateUserName }} <span class="belong" v-if="item.belongName">{{ item.belongName || "" }}</span>
              </div>
              <div>
                <span class="del" @click="repliesDel(item, index)" v-if="item.isOwner">删除</span>
                <!-- <i class="iconfont icon-gengduo"></i> -->
              </div>
            </div>
            <div v-if="item.plnr" class="plnr" v-html="item.plnr"></div>
            <div class="file-imgs" v-if="item.img && item.img.length">
              <template v-for="(img, index) in item.img">
                <div class="item" :key="index" v-if="index < listImagesLen">
                  <el-image :src="img" fit="contain" :preview-src-list="item.previewImg" />
                </div>
              </template>
              <div class="item" v-if="item.img.length > listImagesLen" @click="listImagesLen = maxCount">
                +{{ item.img.length - listImagesLen }}张
              </div>
            </div>
            <div class="floor-page">
              <div>{{ item.showTime }}</div>
              <div class="tools">
                <i
                  v-if="openCollect"
                  class="iconfont"
                  :class="[item.btnNumMap && item.btnNumMap.collect.isDo ? 'icon-shoucang_shixin' : 'icon-shoucang']"
                  title="收藏"
                  @click="btnDoHandle(item, 'collect')"
                >
                  {{ item.btnNumMap.collect.num || 0 }}
                </i>
                <i class="iconfont icon-pinglun" title="回复" @click="replyShow(item)"> {{ item.totalNum || 0 }}</i>
                <i
                  class="iconfont"
                  :class="[item.btnNumMap && item.btnNumMap.tags.isDo ? 'icon-dianzan_kuai' : 'icon-dianzan']"
                  title="点赞"
                  @click="btnDoHandle(item, 'tags')"
                >
                  {{ item.btnNumMap.tags.num || 0 }}</i
                >
              </div>
            </div>
            <div class="children-input" v-if="replyId == item.dsfaCommentId">
              <div
                :ref="`child-plnr-${item.dsfaCommentId}`"
                class="dsf-bbs-detail-textarea"
                placeholder="请输入你的回复"
                contenteditable
                @input="textInput($event, item)"
              ></div>
              <p class="maxlength">
                当前已输入{{ childLength }}个字符, 您还可以输入{{ maxLength - childLength >= 0 ? maxLength - childLength : 0 }}个字符。
              </p>
              <div class="children-emoji-btn">
                <div class="flex-emoji">
                  <el-popover :append-to-body="false" placement="bottom-start" popper-class="tool-popper" trigger="click">
                    <bbs-emoji @change="emojiChange($event, item)" />
                    <el-button slot="reference" type="text" size="medium" class="tool-btn" icon="iconfont icon-xiaolian" title="表情">
                      表情
                    </el-button>
                  </el-popover>
                </div>
                <el-button :disabled="childDisabled" size="small" class="submit-btn" @click="commentReply(item)">{{ btnText }}</el-button>
              </div>
            </div>
            <div class="child-list" v-if="item.totalNum > 0">
              <!-- <p class="expand-btn">
                <span @click="item.check = !item.check" :class="{ active: item.check }"> {{ item.check ? "收起" : "展开" }}回复</span>
              </p> -->
              <div class="expand-content" v-show="item.check">
                <template v-for="(child, cindex) in item.childList">
                  <div class="expand-item" :key="child.dsfaCommentId">
                    <div class="user-info" @click="userInfo(child)">
                      <el-avatar :src="'/oua2/getUserPhoto?type=relativePath&userId=' + child.dsCreateUserId" @error="errorHandler">
                        <i class="el-icon-picture-outline-round" style="font-size: 48px; line-height: 48px"></i>
                      </el-avatar>
                    </div>
                    <div class="expand-plrn">
                      <div class="user-name">
                        <div class="name">
                          {{ child.dsCreateUserName }} <span class="belong" v-if="child.belongName">{{ child.belongName || "" }}</span>
                        </div>
                        <div>
                          <span class="del" @click="repliesDel(child, index, cindex)" v-if="child.isOwner">删除</span>
                        </div>
                      </div>
                      <div v-if="child.dsCreateUserId !== child.bplrValue" class="plnr">
                        <span class="m-user">{{ child.dsCreateUserName }} </span>&nbsp;回复&nbsp; <span class="user">{{ child.bplrText }}：</span>
                        <span v-html="child.plnr" class="c-plnr"></span>
                      </div>
                      <div v-else class="plnr">
                        <span class="m-user">{{ child.dsCreateUserName }}：</span>
                        <span v-html="child.plnr" class="c-plnr"></span>
                      </div>

                      <!-- <div class="file-imgs">
                        <template v-for="(item, index) in fileList">
                          <div class="item" :key="item.id" v-if="index < listImagesLen">
                            <el-image :src="dsf.url.getWebPath(item.relativePath)" :alt="item.originalFileName" :preview-src-list="srcList" />
                          </div>
                        </template>
                        <div class="item" v-if="fileList.length > listImagesLen" @click="listImagesLen = maxCount">
                          +{{ fileList.length - listImagesLen }}张
                        </div>
                      </div> -->
                      <div class="floor-page">
                        <div>{{ child.dsCreateTime | dateTime }}</div>
                        <div class="tools">
                          <i class="iconfont icon-pinglun" title="回复" @click="replyShow(child)"></i>
                          <i
                            class="iconfont"
                            :class="[child.btnNumMap && child.btnNumMap.tags.isDo ? 'icon-dianzan_kuai' : 'icon-dianzan']"
                            title="点赞"
                            @click="btnDoHandle(child, 'tags')"
                          >
                            {{ child.btnNumMap.tags.num || 0 }}</i
                          >
                        </div>
                      </div>
                      <div class="children-input" v-if="replyId == child.dsfaCommentId">
                        <div
                          :ref="`child-plnr-${child.dsfaCommentId}`"
                          class="dsf-bbs-detail-textarea"
                          placeholder="请输入你的回复"
                          contenteditable
                          @input="textInput($event, child)"
                        ></div>
                        <p class="maxlength">
                          当前已输入{{ childLength }}个字符, 您还可以输入{{ maxLength - childLength >= 0 ? maxLength - childLength : 0 }}个字符。
                        </p>
                        <div class="children-emoji-btn">
                          <div class="flex-emoji">
                            <el-popover :append-to-body="false" placement="bottom-start" popper-class="tool-popper" trigger="click">
                              <bbs-emoji @change="emojiChange($event, child)" />
                              <el-button slot="reference" type="text" size="medium" class="tool-btn" icon="iconfont icon-xiaolian" title="表情">
                                表情
                              </el-button>
                            </el-popover>
                          </div>
                          <el-button :disabled="childDisabled" size="small" class="submit-btn" @click="commentReply(child)">{{ btnText }}</el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <p class="more" v-if="item.totalNum > 0" @click="queryChildComment(item)">
                <span :class="{ 'is-more': item.ismore }">
                  <template v-if="item.ismore"> 收起 <i class="iconfont icon-icon_up"></i> </template>
                  <template v-else> 共{{ item.totalNum }}条回复，展开 <i class="iconfont icon-icon_down"></i> </template>
                </span>
              </p>
              <!-- <p class="more" v-if="item.check && item.childList.length > listChildContent" @click="queryChildComment(item)">
                <span :class="{ 'is-more': item.ismore }">
                  {{ item.ismore ? "没有更多了..." : `查看${item.childList.length - listChildContent}条回复 >` }}
                </span>
              </p> -->
            </div>
          </div>
        </div>
        <el-pagination
          layout="prev, pager, next"
          :current-page.sync="queryParams.fPageNo"
          :page-size="queryParams.fPageSize"
          :total="detailContent.total"
          @current-change="queryData"
        />
      </template>
      <div v-else class="not-data">暂无数据</div>
      <!-- </template>
              <template v-if="item.name == 'second'"></template>
              <template v-if="item.name == 'third'"></template>
            </el-tab-pane>
          </template>
        </el-tabs> -->
    </div>
  </div>
</template>

<script>
import BbsEmoji from "../components/BbsEmoji";

export default dsf.component({
  name: "DsfPcReplyComponent",
  ctrlCaption: "PC评论组件",
  mixins: [$mixins.control],
  props: {
    ywlxValue: {
      type: String,
      default: "bbs_topic",
    },
    // 评论列表的请求地址
    commentListUrl: {
      type: String,
      default: "/dsfa/platform/comment/queryComment",
    },
    // 评论的请求地址
    replyUrl: {
      type: String,
      default: "/dsfa/platform/comment/addOrRefreshComment",
    },
    // 回复列表的请求地址
    replyListUrl: {
      type: String,
      default: "/dsfa/platform/comment/queryChildComment",
    },
    //  删除自己回复的请求地址
    delComment: {
      type: String,
      default: "/dsfa/platform/comment/delCommentsBySelf",
    },
    btnText: {
      type: String,
      default: "发布",
    },
    bts: {
      type: Array,
      default() {
        return ["img", "emoji"];
      },
    },
    requestUrl: {
      type: String,
      default: "file/upload/",
    },
    uploadFileExts: {
      type: String,
      default: "image/jpeg,image/jpg,image/png",
    },
    maxCount: {
      type: [Number, String],
      default: 9,
    },
    maxLength: {
      type: Number,
      default: 500,
    },
    openCollect: {
      type: Boolean,
      default: false,
    },
    promptText: {
      type: String,
      default: "请在上课当天进行操作",
    },
    orderWay: {
      type: String,
      default: "up",
    },
    orderType: {
      type: String,
      default: "time",
    },
    childPageSize: {
      type: [Number, String],
      default: 10,
    },
  },
  data() {
    return {
      activeName: "first",
      replyLoading: false,
      queryParams: {
        pageNo: 0,
        pageSize: 10,
        childPageNo: 0,
        childPageSize: 0,
        keywords: "",
        businessType: this.ywlxValue,
        businessId: null,
        orderWay: this.orderWay,
        orderType: this.orderType,
        btnPara: JSON.stringify([
          {
            level: "-1",
            btn: [
              { name: "tags", ywlx: this.ywlxValue },
              { name: "collect", ywlx: this.ywlxValue },
              { name: "concern", ywlx: this.ywlxValue },
              { name: "subscribe", ywlx: this.ywlxValue },
            ],
          },
        ]),
      },
      detailContent: null,
      replyId: "",
      // fileList: dsf.storage.session.get("fileList") || [],
      fileList: [],
      upfileLoading: false,
      percentage: 0,
      headersParam: {
        authorization_token: dsf.getToken(),
      },
      tabs: [
        {
          name: "first",
          label: "全部",
        },
        {
          name: "second",
          label: "图片",
        },
        {
          name: "third",
          label: "文件",
        },
      ],
      listImagesLen: 6,
      listChildContent: 2,
      btnDisabled: true,
      childDisabled: true,
      nettyPlnrData: [],
      addRefreshComment: [],
      addListComment: [],
      userId: dsf.cookies.get("_p"),
      deployNetty: dsf.config.setting_comment_is_netty_use == 1 ? true : false,
      currentLength: 0,
      childLength: 0,
    };
  },
  created() {
    if (this.isDesign) {
      this.detailContent = {
        comment: [
          {
            dsfaCommentId: "aef6814ab3744446b40b78b46f2abec4",
            dsCreateUserId: "830acf375c5b43caae596a69f0562346",
            dsCreateUserName: "评论人",
            dsCreateTime: "2021-11-07 16:41:02",
            showTime: "11分钟前",
            plnr: '<img data-key="1.gif" class="emoji" src="static/img/bbs/1.gif">专注“数智培训、数智治理、数智安全”三大业务方向',
            treeinfoPid: null,
            treeinfoLevel: 1,
            bplrValue: null,
            bplrText: null,
            ywlxValue: "bbs_topic",
            ywlxText: "班级活动",
            ywid: "983a69fbbf7f41bd8f0505d8761b5236",
            keywords: null,
            statusIcon: null,
            statusValue: "0",
            statusText: "正常",
            img: [],
            ywUrl: null,
            ywTitle: null,
            childNum: "4",
            childTotal: 0,
            belongId: null,
            belongName: null,
            belongType: null,
            attach: null,
            btnNumMap: {
              subscribe: { isDo: false, num: 0 },
              collect: { isDo: false, num: 1 },
              tags: { isDo: false, num: 2 },
              concern: { isDo: false, num: 0 },
            },
            childList: [
              {
                dsfaCommentId: "d60c9b2c4f9a4c3e9b27a17c35812fbf",
                dsCreateUserId: "6a8f3de00c2942a5baa685bb621b5e4e",
                dsCreateUserName: "评论人2",
                dsCreateTime: "2021-11-07 16:42:45",
                showTime: "9分钟前",
                plnr: "全心全意为创新服务",
                treeinfoPid: "aef6814ab3744446b40b78b46f2abec4",
                treeinfoLevel: 2,
                bplrValue: "830acf375c5b43caae596a69f0562346",
                bplrText: "评论人",
                ywlxValue: "bbs_topic",
                ywlxText: "班级活动",
                ywid: "983a69fbbf7f41bd8f0505d8761b5236",
                keywords: null,
                statusIcon: null,
                statusValue: "0",
                statusText: "正常",
                img: "",
                ywUrl: null,
                ywTitle: null,
                childNum: null,
                childTotal: 0,
                belongId: null,
                belongName: null,
                belongType: null,
                attach: null,
                btnNumMap: {
                  subscribe: { isDo: false, num: 0 },
                  collect: { isDo: false, num: 0 },
                  tags: { isDo: false, num: 1 },
                  concern: { isDo: false, num: 0 },
                },
                childList: null,
                isTop: false,
                isOwner: false,
                top: false,
              },
              {
                dsfaCommentId: "5369a4dca94940768559c5532b8449b9",
                dsCreateUserId: "6a8f3de00c2942a5baa685bb621b5e4e",
                dsCreateUserName: "评论人2",
                dsCreateTime: "2021-11-07 16:43:30",
                showTime: "9分钟前",
                plnr: "智能化助力治理能力深度现代化",
                treeinfoPid: "aef6814ab3744446b40b78b46f2abec4",
                treeinfoLevel: 2,
                bplrValue: "830acf375c5b43caae596a69f0562346",
                bplrText: "评论人",
                ywlxValue: "bbs_topic",
                ywlxText: "班级活动",
                ywid: "983a69fbbf7f41bd8f0505d8761b5236",
                keywords: null,
                statusIcon: null,
                statusValue: "0",
                statusText: "正常",
                img: "",
                ywUrl: null,
                ywTitle: null,
                childNum: null,
                childTotal: 0,
                belongId: null,
                belongName: null,
                belongType: null,
                attach: null,
                btnNumMap: {
                  subscribe: { isDo: false, num: 0 },
                  collect: { isDo: false, num: 0 },
                  tags: { isDo: false, num: 0 },
                  concern: { isDo: false, num: 0 },
                },
                childList: null,
                isTop: false,
                isOwner: false,
                top: false,
              },
              {
                dsfaCommentId: "538704dcb3014644b0d70a984b231729",
                dsCreateUserId: "6a8f3de00c2942a5baa685bb621b5e4e",
                dsCreateUserName: "评论人2",
                dsCreateTime: "2021-11-07 16:43:40",
                showTime: "9分钟前",
                plnr: "哈",
                treeinfoPid: "aef6814ab3744446b40b78b46f2abec4",
                treeinfoLevel: 2,
                bplrValue: "830acf375c5b43caae596a69f0562346",
                bplrText: "评论人",
                ywlxValue: "bbs_topic",
                ywlxText: "班级活动",
                ywid: "983a69fbbf7f41bd8f0505d8761b5236",
                keywords: null,
                statusIcon: null,
                statusValue: "0",
                statusText: "正常",
                img: "",
                ywUrl: null,
                ywTitle: null,
                childNum: null,
                childTotal: 0,
                belongId: null,
                belongName: null,
                belongType: null,
                attach: null,
                btnNumMap: {
                  subscribe: { isDo: false, num: 0 },
                  collect: { isDo: false, num: 0 },
                  tags: { isDo: false, num: 1 },
                  concern: { isDo: false, num: 0 },
                },
                childList: null,
                isTop: false,
                isOwner: false,
                top: false,
              },
            ],
            isTop: false,
            isOwner: false,
            top: false,
            check: true,
            ismore: false,
          },
        ],
        total: 1,
      };
    } else {
      this.queryParams.businessId = this.$route.query.id;
      this.queryData();
    }
  },
  computed: {
    srcList() {
      return this.fileList.map((v) => {
        if (v.id) {
          return v.relativePath;
        }
        return dsf.url.getWebPath(v.relativePath);
      });
    },
  },
  mounted() {
    if (this.deployNetty) {
      this.nettyStatus = dsf.ws();
      this.nettyStatus.$on(
        "/bbs/handle/comment",
        {
          id: this.queryParams.businessId,
        },
        (res) => {
          let resData = res.data;
          let listData = this.detailContent.comment;
          if (resData?.ywid && resData.ywid == this.queryParams.businessId) {
            if (resData.treeinfoLevel == 1) {
              if (_.isEmpty(resData.btnNumMap) || _.isEmpty(resData.btnNumMap.tags)) {
                resData.btnNumMap = {
                  subscribe: { isDo: false, num: 0 },
                  collect: { isDo: false, num: 0 },
                  tags: { isDo: false, num: 0 },
                  concern: { isDo: false, num: 0 },
                };
              }
              resData.plnr = resData.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
              resData.previewImg = resData.img ? this.replyImgList(resData.img, "preview") : [];
              resData.img = resData.img ? this.replyImgList(resData.img, "list") : [];
              if (this.userId != resData.dsCreateUserId) {
                listData.unshift(resData);
              }
            } else {
              let fid = resData.fid;
              _(listData).forEach((list) => {
                if (_.isEmpty(resData.btnNumMap) || _.isEmpty(resData.btnNumMap.tags)) {
                  resData.btnNumMap = {
                    subscribe: { isDo: false, num: 0 },
                    collect: { isDo: false, num: 0 },
                    tags: { isDo: false, num: 0 },
                    concern: { isDo: false, num: 0 },
                  };
                }
                // 获取当前是哪一评论 再给  子级添加
                if (list.dsfaCommentId == fid) {
                  // if (!list.childList || _.isNull(list.childList)) {
                  //   list.childList = []
                  // }
                  // let totalNum = Number(list.totalNum)
                  // if( totalNum > 0) {
                  //   list.childList.unshift(resData);
                  //   this.$set(list, "totalNum", totalNum +1);
                  // } else {
                  //   list.childList.push(resData);
                  //   this.$set(list, "totalNum", list.childList.length);
                  //   this.$set(list, "ismore", true);
                  // }
                  // // 如果当前已经点开过，再添加数据
                  // if(list.totalNum == list.childList.length) {
                  //   this.$set(list, "ismore", true);
                  // }
                  // this.$set(list, "check", true);

                  if (!list.childList || _.isNull(list.childList)) {
                    this.$set(list, "childList", []);
                  }
                  // console.log("子级", list.childList, this.userId, resData.dsCreateUserId);
                  if (this.userId != resData.dsCreateUserId) {
                    list.childList.unshift(resData);
                    this.$set(list, "check", true);
                  }
                }
              });
            }
          }
        }
      );
    }
  },
  methods: {
    textInput(e, item) {
      let btnStatus = e.target.innerHTML && e.target.innerHTML.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "").length > 0 ? false : true;
      if (item) {
        let type = "child-plnr-" + item.dsfaCommentId;
        this.childLength = this.getTextLength(this.$refs[type][0]);
        this.childDisabled = this.childLength > this.maxLength ? true : btnStatus;
      } else {
        this.currentLength = this.getTextLength(this.$refs.textarea);
        this.btnDisabled = this.currentLength > this.maxLength ? true : btnStatus;
      }
    },
    getTextLength(dom) {
      var mytext = dom.innerHTML.replace(new RegExp("&nbsp;", "g"), ""); //删除空格"&nbsp;"
      var textarr = mytext.split("<");
      var contentlength = 0;
      _.forEach(textarr, function (item) {
        var index = item.indexOf(">");
        if (item[index] !== -1) {
          contentlength = contentlength + item.substr(index + 1, item.length).length;
        }
      });
      var imgNum = this.searchTimes(dom.innerHTML, "<img"); //图片个数
      var spaceNum = this.searchTimes(dom.innerHTML, "&nbsp;"); //空格个数
      contentlength = contentlength + imgNum + spaceNum;
      return contentlength;
    },
    searchTimes(text, word) {
      var index = text.indexOf(word);
      var num = 0;
      while (index !== -1) {
        num++;
        index = text.indexOf(word, index + 1);
      }
      return num;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handlePreview(file) {
      // console.log(file);
    },
    queryData(pageNo) {
      // 最新 最热
      if (dsf.isDef(pageNo)) {
        this.queryParams.pageNo = pageNo;
      } else {
        this.queryParams.pageNo = 0;
      }
      this.replyLoading = true;
      this.$http
        .get(this.commentListUrl, this.queryParams, $$webRoot.bbs)
        .done(({ success, data, message }) => {
          if (success) {
            _(data.comment).forEach((item, index) => {
              // 展开 收起 加载更多
              this.$set(item, "check", false);
              this.$set(item, "ismore", false);
              this.$set(item, "childNextPage", 1);
              // 处理子级  默认没有子级 要设置成空数组
              item.childList = [];
              // if (item.childList) {
              //   _(item.childList).forEach((item) => {
              //     if (item.plnr) {
              //       item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
              //     }
              //   });
              // }
              // 当前评论
              if (item.plnr) {
                item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
              }
              item.previewImg = item.img ? this.replyImgList(item.img, "preview") : [];
              item.img = item.img ? this.replyImgList(item.img, "list") : [];
            });
            this.detailContent = data;
            // this.replyId = "";
          } else {
            dsf.layer.message(message || "请求异常", false);
          }
        })
        .always(() => {
          this.replyLoading = false;
        });
    },
    replyShow(item) {
      if (!item.dsfaCommentId) return;
      this.childDisabled = true;
      this.childLength = 0;
      // 当前已点开 就缩回
      this.replyId = this.replyId != item.dsfaCommentId ? item.dsfaCommentId : "";
    },
    commentReply(item) {
      if (this.$listeners["comment-btn"]) {
        this.$dispatch("comment-btn", item);
      } else {
        this.comment(item);
      }
    },
    comment(item) {
      let params = {
        plnr: "",
        ywlxValue: this.ywlxValue,
        ywid: this.queryParams.businessId,
        treeinfoPid: item?.dsfaCommentId,
        img: null,
      };
      let imgList = null;
      if (item.dsfaCommentId) {
        params.plnr = this.getContent("child-plnr-" + item.dsfaCommentId);
      } else {
        // 过滤掉上传失败的图片
        if (this.fileList.length) {
          imgList = this.fileList.filter((f) => !!f);
          if (imgList.length < this.fileList.length) {
            dsf.layer
              .confirm({
                message: "上传失败的图片将被忽略，是否继续？",
                confirmButtonText: "继续",
              })
              .then(() => {
                //console.log(1, imgList);
                params.img = JSON.stringify(imgList);
              })
              .catch(() => {});
          } else {
            //console.log(2, imgList);
            params.img = JSON.stringify(imgList);
          }
        }
        params.plnr = this.getContent();
      }
      if (params.plnr == "" && !params.img) {
        dsf.layer.message("请输入内容~", false);
        return;
      }
      // 有图片有问题
      // if (this.currentLength > this.maxLength) {
      //   dsf.layer.message(`当前字数为${plnrlength}字,评论字数限制在${this.maxLength}字以内`, false);
      //   return;
      // }
      this.$http
        .post(this.replyUrl, params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            let resData = res.data;
            if (resData.treeinfoLevel == 1) {
              if (_.isEmpty(resData.btnNumMap) || _.isEmpty(resData.btnNumMap.tags)) {
                resData.btnNumMap = {
                  subscribe: { isDo: false, num: 0 },
                  collect: { isDo: false, num: 0 },
                  tags: { isDo: false, num: 0 },
                  concern: { isDo: false, num: 0 },
                };
              }
              resData.plnr = resData.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
              resData.previewImg = resData.img ? this.replyImgList(resData.img, "preview") : [];
              resData.img = resData.img ? this.replyImgList(resData.img, "list") : [];
              this.detailContent.total += 1;
              this.detailContent.comment.unshift(resData);
              // console.log("平级", this.detailContent.comment);
            } else {
              let fid = resData.fid;
              _(this.detailContent.comment).forEach((list) => {
                if (_.isEmpty(resData.btnNumMap) || _.isEmpty(resData.btnNumMap.tags)) {
                  resData.btnNumMap = {
                    subscribe: { isDo: false, num: 0 },
                    collect: { isDo: false, num: 0 },
                    tags: { isDo: false, num: 0 },
                    concern: { isDo: false, num: 0 },
                  };
                }
                // 获取当前是哪一评论 再给  子级添加
                if (list.dsfaCommentId == fid) {
                  if (!list.childList || _.isNull(list.childList)) {
                    list.childList = [];
                  }
                  let totalNum = Number(list.totalNum);
                  if (totalNum > 0) {
                    list.childList.unshift(resData);
                    this.$set(list, "totalNum", totalNum + 1);
                  } else {
                    list.childList.push(resData);
                    this.$set(list, "totalNum", list.childList.length);
                    this.$set(list, "ismore", true);
                  }
                  // 如果当前已经点开过，再添加数据
                  if (list.totalNum == list.childList.length) {
                    this.$set(list, "ismore", true);
                  }
                  this.$set(list, "check", true);
                }
              });
            }

            dsf.layer.message(res.message);
            this.reset(item.dsfaCommentId);
            // this.queryData();
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    queryChildComment(item) {
      if (item.ismore) {
        item.totalNum = item.childList.length;
        item.check = false;
        item.ismore = false;
        return;
      }
      item.check = true;
      // 当前有数据 并且不等于总数 就请求接口
      if (item.totalNum != item.childList.length) {
        let params = {
          childPageNo: item.childNextPage,
          childPageSize: this.childPageSize,
          businessType: this.ywlxValue,
          businessId: this.queryParams.businessId,
          pId: item.dsfaCommentId,
          btnPara: JSON.stringify([
            {
              level: "-1",
              btn: [
                { name: "tags", ywlx: this.ywlxValue },
                { name: "collect", ywlx: this.ywlxValue },
                { name: "concern", ywlx: this.ywlxValue },
                { name: "subscribe", ywlx: this.ywlxValue },
              ],
            },
          ]),
        };
        this.$http
          .get(this.replyListUrl, params, $$webRoot.bbs)
          .done((res) => {
            if (res.success && res.state == 20000) {
              let data = res.data;
              if (data.dsfaCommentBoList.length) {
                _(data.dsfaCommentBoList).forEach((item) => {
                  if (item.plnr) {
                    item.plnr = item.plnr.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "");
                  }
                });

                if (data.remainNum > 0) {
                  item.childNextPage = data.nextPage;
                  item.totalNum = data.remainNum;
                } else {
                  item.ismore = true;
                }
                // 添加第一页面
                item.childList =
                  item.childList.length > 1 ? _.uniqBy(_.concat(item.childList, data.dsfaCommentBoList), "dsfaCommentId") : data.dsfaCommentBoList;
                // this.listChildContent = params.childPageSize;
              }
            } else {
              dsf.layer.message(res.message, false);
            }
          })
          .error((err) => {
            dsf.layer.message(err.message, false);
          });
      } else {
        item.ismore = true;
      }
    },
    btnDoHandle(item, type) {
      // console.log(item, type);
      let info = type == "collect" ? "收藏" : "点赞";
      if (item.btnNumMap[type].isDo == true) {
        dsf.layer
          .confirm({
            title: "信息",
            message: "确认取消" + info + "吗？",
          })
          .then(() => {
            this.saveOrCancel(item, type);
          })
          .catch(() => {});
      } else {
        this.saveOrCancel(item, type);
      }
    },
    saveOrCancel(item, type) {
      let params = {
        ywlxValue: this.ywlxValue,
        ywlxText: "BBS主题",
        ywid: item.dsfaCommentId,
      };
      let url = "dsfa/" + type + "/saveOrCancel";
      this.$http
        .post(url, params, $$webRoot.bbs)
        .done((res) => {
          if (res.success && res.state == 20000) {
            // console.log("成功", item);
            item.btnNumMap[type].isDo = res.data.isDo;
            item.btnNumMap[type].num = res.data.isDo ? Number(item.btnNumMap[type].num) + 1 : Number(item.btnNumMap[type].num) - 1;
            dsf.layer.message(res.message);
          } else {
            dsf.layer.message(res.message, false);
          }
        })
        .error((err) => {
          dsf.layer.message(err.message, false);
        });
    },
    errorHandler() {
      return true;
    },
    userInfo(data) {
      // if (data.dsfaCommentId) {
      //  this.$openWindow("#/pc/bbs/home?u=" + data.dsCreateUserId);
      // }
    },
    // 只看楼主
    checkTpye() {
      this.queryParams.vType = this.queryParams.vType == 0 ? 1 : 0;
      this.queryData();
    },
    getContent(type) {
      let text = null;
      if (type) {
        text = this.$refs[type][0].innerHTML;
      } else {
        // 底部的评论框
        text = this.$refs.textarea.innerHTML;
      }
      text = text.replace(/(^\s*|&nbsp;)|(\s*$)/g, "");
      return text;
    },
    emojiChange(item, list) {
      let img = document.createElement("img");
      img.dataset.key = item.key;
      img.classList.add("emoji");
      img.src = item.value;
      if (list) {
        this.$refs["child-plnr-" + list.dsfaCommentId][0].appendChild(img);
        this.childLength = this.getTextLength(this.$refs["child-plnr-" + list.dsfaCommentId][0]);
        this.childDisabled = this.childLength > this.maxLength ? true : false;
      } else {
        this.$refs.textarea.appendChild(img);
        this.currentLength = this.getTextLength(this.$refs.textarea);
        this.btnDisabled = this.currentLength > this.maxLength ? true : false;
      }
    },
    reset(type) {
      if (type) {
        this.childLength = 0
        this.childDisabled = true;
        this.$refs["child-plnr-" + type][0].innerHTML = "";
      } else {
        this.currentLength = 0
        this.fileList = [];
        this.btnDisabled = true;
        this.$refs.textarea.innerHTML = "";
      }
    },
    repliesDel(item, i, childIndex) {
      dsf.layer
        .confirm("确认要删除当前回复吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.$http
            .post(
              "/dsfa/platform/comment/delCommentsBySelf",
              { ids: JSON.stringify([item.dsfaCommentId]) },
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              },
              $$webRoot.bbs
            )
            .done((res) => {
              if (res.success && res.state == 20000) {
                dsf.layer.message(res.message);
                if (childIndex >= 0) {
                  this.detailContent.comment[i].childList.splice(childIndex, 1);
                  this.detailContent.comment[i].totalNum = Number(this.detailContent.comment[i].totalNum) - 1;
                } else {
                  this.detailContent.comment.splice(i, 1);
                }
                // 一级评论做统计
                if (item.treeinfoLevel == 1) {
                  this.detailContent.total -= 1;
                }
                // 如果删除当前分页所有帖子 还有多的就获取下新数据
                if (this.detailContent.comment.length < 1 && this.detailContent.total > 1) {
                  this.queryData();
                }
              } else {
                dsf.layer.message(res.message, false);
              }
            })
            .error((err) => {
              dsf.layer.message(err.message, false);
            });
        })
        .catch(() => {});
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    handleProgress(response, file) {
      this.upfileLoading = true;
      this.percentage = file.percentage.toFixed(0);
    },
    handleRemove(index) {
      this.$refs.upload.uploadFiles.splice(index, 1);
      this.fileList.splice(index, 1);
      let text = this.$refs.textarea.innerHTML.replace(/\&nbsp;|<br>|<(?!img).*?>/gi, "").length > 0;
      this.btnDisabled = this.fileList.length > 0 || text ? false : true;
    },
    beforeUpload(file) {
      // 判断文件格式
      if (this.uploadFileExts) {
        let arr = _.filter(this.uploadFileExts?.split?.(",") || [], (it) => it);
        if (!~arr.indexOf(file.type)) {
          dsf.layer.message("上传图片格式不正确", false);
          return false;
        }
      }
    },
    handleExceed() {
      dsf.layer.message(`当前最多上传 ${this.maxCount} 张图片`, false);
    },
    //上传失败
    handleError() {
      this.upfileLoading = false;
      dsf.layer.message("上传失败", false);
    },
    //上传成功
    handleSuccess(res) {
      this.upfileLoading = false;
      if (res.success && res.state == 20000) {
        this.btnDisabled = false;
        // console.log(res, this.fileList);
        // let img = this.getImgList(res.data);
        this.fileList.push(...res.data);
        // this.percentage = 100;
        // dsf.storage.session.set("fileList", this.fileList);
      } else {
        dsf.layer.message(res.message, false);
      }
    },
    replyImgList(imgs, type) {
      if (!imgs || imgs === "[]" || _.isEqual(imgs, [])) {
        return [];
      }
      let _imgs = !_.isArray(imgs) ? JSON.parse(imgs) : imgs;
      let compSole = type == "list" ? "/bbs/image/compSole?scale=3&srcPath=" : "";
      return _imgs.map((v) => (v.relativePath ? dsf.url.getWebPath(compSole + v.relativePath) : v));
    },
  },
  beforeDestroy() {
    if (this.deployNetty) {
      this.nettyStatus.$end();
    }
  },
  components: {
    BbsEmoji,
  },
});
</script>
